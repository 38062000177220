<template>
    <header class="dating-header">
        <the-navbar
            v-if="!anonymous"
            @update="update"
        />
        <div v-else class="dating-header__icon">
            <v-img
                v-if="dataImage.hasBackground"
                :src="require('../../assets/images/icons/icon-background.svg')"
                class="dating-header__icon-background"
                contain
                height="144"
            />
            <v-img
                :src="require(`../../assets/images/${dataImage.fileName}`)"
                class="dating-header__icon-image"
                contain
                :height="dataImage.height"
            />
        </div>
    </header>
</template>

<script>
import TheNavbar from './TheNavbar'

export default ({
    props: {
        anonymous: {
            type: Boolean,
            required: true,
            default: true,
        },
        dataImage: {
            fileName: {
                type: String,
                required: true,
            },
            height: {
                type: Number,
                required: true,
            },
            hasBackground: {
                type: Boolean,
                required: true,
            },
        },
    },
    data: () => ({
        //
    }),
    methods: {
        update () {
            this.$emit('update')
        },
    },
    components: {
        TheNavbar,
    },
})
</script>

<style lang="scss" scoped>
.dating-header {
    background: map-get($material-theme, 'surface');
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 12px 0;
    border-radius: 0 0 32px 32px;

    &__icon {
        background: url(../../assets/images/icons/dating-header-back.svg) no-repeat 50% 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 170px;

        &-background {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-image {
            position: relative;
        }
    }
}

</style>
