<template>
    <div id="app-width">
        <the-header
            :anonymous="true"
            :data-image="{
                fileName: 'logo.svg',
                height: '60',
                hasBackground: false,
            }"
        />
        <v-container fluid class="welcome flex-container text-center">
            <h1 class="welcome__title mt-auto mb-8">Анонимные знакомства <br> без регистрации</h1>
            <p class="welcome__text mb-12">
                Здесь не требуется регистрация, загрузка фотографий, заполнение сложной анкеты и уж тем более подтверждение ее телефоном или соцсетью.
                Все общение идет через email, больше ничего не потребуется. Ввиду анонимности, на сайте много объявлений секс тематики, которые не стоит читать детям.
                Подтвердите, что вам есть 18 лет, для того, чтобы перейти к поиску объявлений.
            </p>
            <v-row justify="center" class="flex-grow-0 mt-auto">
                <v-col cols="6">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="secondary"
                        to="/no-entry"
                    >
                        Меньше 18 лет
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click="auth"
                    >
                        Мне есть 18 лет
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TheHeader from '../app/TheHeader.vue'

export default {
    name: 'DefaultController',
    components: {
        TheHeader,
    },
    computed: {
        isAuthenticated () {
            return this.$store.getters.isAuthenticated
        },
    },
    methods: {
        auth () {
            if (this.isAuthenticated) {
                this.$router.push('/config')
                return
            }

            this.$axios.post('/api/frontend/auth')
                .then((res) => {
                    if (res.data.success) {
                        this.$axios.defaults.headers.common['Authorization'] = res.data.data.token
                        this.$store.commit('updateToken', res.data.data.token)
                        this.$router.push('/config')
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.welcome__text {
    font-size: .875rem;
    line-height: 1.5;
}
</style>
