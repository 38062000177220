<template>
  <div class="navbar">
    <v-btn
      class="navbar__btn"
      depressed
      height="44"
      :color="configShow ? 'primary' : 'transparent'"
      @click.stop="configShow = true"
    >
      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 23.5V9.949C1.308 9.704 0 8.258 0 6.5C0 4.742 1.308 3.296 3 3.051V0.5C3 0.224 3.224 0 3.5 0C3.776 0 4 0.224 4 0.5V3.051C5.692 3.296 7 4.742 7 6.5C7 8.258 5.692 9.704 4 9.949V23.5C4 23.776 3.776 24 3.5 24C3.224 24 3 23.776 3 23.5ZM6 6.5C6 5.122 4.878 4 3.5 4C2.122 4 1 5.122 1 6.5C1 7.878 2.122 9 3.5 9C4.878 9 6 7.878 6 6.5Z" fill="#2D2D2D"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.5C12 23.776 11.776 24 11.5 24C11.224 24 11 23.776 11 23.5V18.949C9.308 18.704 8 17.258 8 15.5C8 13.742 9.308 12.296 11 12.051V0.5C11 0.224 11.224 0 11.5 0C11.776 0 12 0.224 12 0.5V12.051C13.692 12.296 15 13.742 15 15.5C15 17.258 13.692 18.704 12 18.949V23.5ZM11.5 13C10.122 13 9 14.122 9 15.5C9 16.878 10.122 18 11.5 18C12.878 18 14 16.878 14 15.5C14 14.122 12.878 13 11.5 13Z" fill="#2D2D2D"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 23.5C20 23.776 19.776 24 19.5 24C19.224 24 19 23.776 19 23.5V12.949C17.308 12.704 16 11.258 16 9.5C16 7.742 17.308 6.296 19 6.051V0.5C19 0.224 19.224 0 19.5 0C19.776 0 20 0.224 20 0.5V6.051C21.692 6.296 23 7.742 23 9.5C23 11.258 21.692 12.704 20 12.949V23.5ZM19.5 7C18.122 7 17 8.122 17 9.5C17 10.878 18.122 12 19.5 12C20.878 12 22 10.878 22 9.5C22 8.122 20.878 7 19.5 7Z" fill="#2D2D2D"></path>
      </svg>
    </v-btn>
    <ConfigModal v-model="configShow" @update="update" />
    <v-btn
      class="navbar__btn"
      depressed
      height="44"
      :color="this.$route.path === '/map' ? 'primary' : 'transparent'"
      :to="this.$route.path === '/map' ? '/list' : '/map'"
    >
      <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.544 21.7044L5.712 19.8574C4.171 16.4384 2.608 13.3704 0.934 10.4794C0.323 9.42439 0 8.22139 0 6.99939C0 3.94639 2.033 1.20139 4.95 0.30739C4.985 0.29239 5.021 0.28139 5.059 0.27539C6.341 -0.0946098 7.685 -0.0916098 8.968 0.28339C8.981 0.28639 8.993 0.29039 9.005 0.29439V0.29339C11.946 1.17339 14 3.93039 14 6.99939C14 8.22139 13.677 9.42439 13.066 10.4794C11.392 13.3704 9.829 16.4384 8.288 19.8574L7.456 21.7044C7.375 21.8844 7.197 21.9994 7 21.9994C6.803 21.9994 6.625 21.8844 6.544 21.7044ZM5.28683 1.25084C5.28167 1.25241 5.27613 1.25411 5.271 1.25539C2.756 2.01239 1 4.37239 1 6.99939C1 8.04539 1.276 9.07539 1.799 9.97739C3.491 12.8994 5.069 15.9964 6.624 19.4474L7 20.2824L7.376 19.4484C8.931 15.9974 10.51 12.9004 12.201 9.97839C12.724 9.07539 13 8.04539 13 6.99939C13 4.37139 11.243 2.01039 8.725 1.25439L8.704 1.24839C7.585 0.91839 6.414 0.91839 5.295 1.24839C5.29249 1.24911 5.28972 1.24995 5.28683 1.25084Z" fill="#2D2D2D"></path>
        <path d="M7 23.9994C10.394 23.9994 14 22.9474 14 20.9994C14 19.8064 12.559 18.7994 10.145 18.3054C9.875 18.2494 9.61 18.4244 9.555 18.6954C9.499 18.9664 9.674 19.2304 9.945 19.2864C12.044 19.7144 13 20.4754 13 20.9994C13 21.8344 10.717 22.9994 7 22.9994C3.283 22.9994 1 21.8344 1 20.9994C1 20.4754 1.956 19.7144 4.056 19.2854C4.327 19.2294 4.501 18.9654 4.446 18.6944C4.391 18.4234 4.126 18.2494 3.856 18.3044C1.441 18.7994 0 19.8064 0 20.9994C0 22.9474 3.606 23.9994 7 23.9994Z" fill="#2D2D2D"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 6.99939C11 9.20539 9.206 10.9994 7 10.9994C4.794 10.9994 3 9.20539 3 6.99939C3 4.79339 4.794 2.99939 7 2.99939C9.206 2.99939 11 4.79339 11 6.99939ZM10 6.99939C10 5.34539 8.654 3.99939 7 3.99939C5.346 3.99939 4 5.34539 4 6.99939C4 8.65339 5.346 9.99939 7 9.99939C8.654 9.99939 10 8.65339 10 6.99939Z" fill="#2D2D2D"></path>
      </svg>
    </v-btn>
    <v-btn
      class="navbar__btn"
      depressed
      height="44"
      :color="createOfferShow ? 'primary' : 'transparent'"
      @click.stop="createOfferShow = true"
    >
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 11.5C23 17.841 17.841 23 11.5 23C5.159 23 0 17.841 0 11.5C0 5.159 5.159 0 11.5 0C17.841 0 23 5.159 23 11.5ZM22 11.5C22 5.71 17.29 1 11.5 1C5.71 1 1 5.71 1 11.5C1 17.29 5.71 22 11.5 22C17.29 22 22 17.29 22 11.5Z" fill="#2D2D2D"></path>
        <path d="M5.5 12H11V17.5C11 17.776 11.224 18 11.5 18C11.776 18 12 17.776 12 17.5V12H17.5C17.776 12 18 11.776 18 11.5C18 11.224 17.776 11 17.5 11H12V5.5C12 5.224 11.776 5 11.5 5C11.224 5 11 5.224 11 5.5V11H5.5C5.224 11 5 11.224 5 11.5C5 11.776 5.224 12 5.5 12Z" fill="#2D2D2D"></path>
      </svg>
    </v-btn>
    <CreateOfferModal v-model="createOfferShow" @update="update" />
  </div>
</template>

<script>
import CreateOfferModal from '../modals/CreateOfferModal'
import ConfigModal from '../modals/ConfigModal'

export default ({
    data: () => ({
        createOfferShow: false,
        configShow: false,
    }),
    components: {
        CreateOfferModal,
        ConfigModal,
    },
    methods: {
        update () {
            this.$emit('update')
        },
    },
})
</script>

<style lang="scss" scoped>
  .navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 0;
    margin: -7px -5px;
  }
  .v-btn {
    min-width: 63px !important;
    &.primary {
      path {
        fill: #FFFFFF;
      }
    }
  }
</style>
