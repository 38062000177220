<template>
    <div id="app-width">
        <the-header
            :anonymous="true"
            :data-image="{
                fileName: 'logo.svg',
                height: '60',
                hasBackground: false,
            }"
        />
        <v-container fluid class="welcome flex-container text-center">
            <h1 class="welcome__title mt-auto mb-8">Подтверждение почты</h1>
            <p class="welcome__text mb-12">
                Пожалуйста, дождитесь окончания процесса подтверждения почты.
            </p>
            <v-row justify="center" class="flex-grow-0 mt-auto">
                <v-col cols="12">
                    <v-btn
                        fab
                        dark
                        :class="{warning: loading, success: success, error: !loading && !success}"
                        :loading="loading"
                    >
                        <v-icon v-if="success">mdi-check</v-icon>
                        <v-icon v-if="!loading && !success">mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TheHeader from '../app/TheHeader.vue'

export default {
    name: 'EmailConfirmController',
    components: {
        TheHeader,
    },
    data: () => ({
        success: false,
        loading: true,
    }),
    created () {
        this.confirm()
    },
    methods: {
        confirm () {
            this.$axios.post('/api/frontend/email/confirm/' + this.$route.params.hash)
                .then((res) => {
                    this.success = res.data.success
                    this.loading = false

                    this.$store.commit('updateCity', {
                        cityID: res.data.data.city_id,
                    })
                    this.$store.commit('updateConfig', {
                        gender: res.data.data.gender,
                        target: res.data.data.target,
                        age: {
                            min: res.data.data.age_min,
                            max: res.data.data.age_max,
                        },
                    })
                    this.$store.commit('updateEmail', {
                        email: res.data.data.email,
                    })
                    this.$store.commit('updateEmailConfirmed', {
                        emailConfirmed: true,
                    })
                    this.$store.commit('updateGoal', {
                        goalID: res.data.data.goal_id,
                    })
                    this.$store.commit('updateMap', {
                        map: {
                            lat: res.data.data.lat,
                            lng: res.data.data.lng,
                        },
                    })
                })
                .catch(() => {})
        },
    },
}
</script>

<style lang="scss" scoped>
.welcome__text {
    font-size: .875rem;
    line-height: 1.5;
}
</style>
