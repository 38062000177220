const state = {
    age: localStorage.getItem('config-age') || null,
    ageMax: localStorage.getItem('config-age-max') || null,
    ageMin: localStorage.getItem('config-age-min') || null,
    cityID: localStorage.getItem('config-city-id') || null,
    cityName: localStorage.getItem('config-city-name') || null,
    email: localStorage.getItem('config-email') || null,
    emailConfirmed: localStorage.getItem('config-email-confirmed') || false,
    gender: localStorage.getItem('config-gender') || null,
    goalID: localStorage.getItem('config-goal-id') || null,
    goalName: localStorage.getItem('config-goal-name') || null,
    mapLat: localStorage.getItem('config-map-lat') || null,
    mapLng: localStorage.getItem('config-map-lng') || null,
    target: localStorage.getItem('config-target') || null,
}

const getters = {
    user (state) {
        return {
            age: state.age,
            ageMax: state.ageMax,
            ageMin: state.ageMin,
            cityID: state.cityID,
            cityName: state.cityName,
            email: state.email,
            emailConfirmed: state.emailConfirmed,
            gender: state.gender,
            goalID: state.goalID,
            goalName: state.goalName,
            mapLat: state.mapLat,
            mapLng: state.mapLng,
            target: state.target,
        }
    },
}

const mutations = {
    removeConfig (state) {
        localStorage.removeItem('config-age')
        localStorage.removeItem('config-age-max')
        localStorage.removeItem('config-age-min')
        localStorage.removeItem('config-city-id')
        localStorage.removeItem('config-city-name')
        localStorage.removeItem('config-email')
        localStorage.removeItem('config-email-confirmed')
        localStorage.removeItem('config-gender')
        localStorage.removeItem('config-goal-id')
        localStorage.removeItem('config-goal-name')
        localStorage.removeItem('config-map-lat')
        localStorage.removeItem('config-map-lng')
        localStorage.removeItem('config-target')
        state.age = null
        state.ageMax = null
        state.ageMin = null
        state.cityID = null
        state.cityName = null
        state.email = null
        state.emailConfirmed = false
        state.gender = null
        state.goalID = null
        state.goalName = null
        state.mapLat = null
        state.mapLng = null
        state.target = null
    },
    updateAge (state, config) {
        localStorage.setItem('config-age', config.age)
        state.age = config.age
    },
    updateCity (state, config) {
        localStorage.setItem('config-city-id', config.cityID)
        localStorage.setItem('config-city-name', config.cityName)
        state.cityID = config.cityID
        state.cityName = config.cityName
    },
    updateConfig (state, config) {
        localStorage.setItem('config-age-max', config.age.max)
        localStorage.setItem('config-age-min', config.age.min)
        localStorage.setItem('config-gender', config.gender)
        localStorage.setItem('config-target', config.target)
        state.ageMax = config.age.max
        state.ageMin = config.age.min
        state.gender = config.gender
        state.target = config.target
    },
    updateEmail (state, config) {
        localStorage.setItem('config-email', config.email)
        state.email = config.email
    },
    updateEmailConfirmed (state, config) {
        localStorage.setItem('config-email-confirmed', config.emailConfirmed)
        state.emailConfirmed = config.emailConfirmed
    },
    updateGoal (state, config) {
        localStorage.setItem('config-goal-id', config.goalID)
        localStorage.setItem('config-goal-name', config.goalName)
        state.goalID = config.goalID
        state.goalName = config.goalName
    },
    updateMap (state, config) {
        localStorage.setItem('config-map-lat', config.map.lat)
        localStorage.setItem('config-map-lng', config.map.lng)
        state.mapLat = config.map.lat
        state.mapLng = config.map.lng
    },
}

export default {
    state,
    getters,
    mutations,
}
