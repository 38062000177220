import Vue from 'vue'
import VueMoment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'

Vue.use(VueMoment)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    created () {
        this.$axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
        this.$axios.defaults.withCredentials = true

        const self = this

        this.$axios.interceptors.response.use(
            function (res) {
                // if (res.headers['x-country'] && self.$store.getters.user) {
                //     self.$store.commit('updateUserCountry', res.headers['x-country'])
                // }

                return Promise.resolve(res)
            },
            function (error) {
                if (error.response.status === 401 || error.response.status === 403) {
                    self.$store.commit('removeToken')
                    self.$store.commit('removeUser')
                    self.$router.push('/')
                    return Promise.reject(error)
                }
            }
        )

        const authToken = localStorage.getItem('auth-token')

        if (authToken) {
            this.$axios.defaults.headers.common['Authorization'] = authToken
        }
    },
}).$mount('#app')
