<template>
    <div id="app-width">
        <the-header
            :anonymous="true"
            :data-image="{
                fileName: 'icons/cityskape.svg',
                height: '124',
                hasBackground: true
            }"
        />
        <v-container fluid class="flex-container justify-center text-center">
            <h2 class="mt-auto mb-8">Ваше местоположение</h2>
            <div class="flex-grow-0 mb-12">
                <city-select
                    v-model="data.city_id"
                    :errors="errors.city_id"
                    @action="setCityID"
                />
            </div>
            <v-row justify="center" class="flex-grow-0 mt-auto">
                <v-col cols="4">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="secondary"
                        to="/config"
                    >
                        Назад
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click="save"
                    >
                        Цель знакомства
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TheHeader from '@/components/app/TheHeader'
import CitySelect from '@/components/app/CitySelect'

export default ({
    name: 'CityController',
    data: () => ({
        data: {
            city_id: 0,
        },
        errors: {
            city_id: [],
        },
    }),
    created () {
        this.data.city_id = parseInt(this.$store.getters.user.cityID || 0)
    },
    methods: {
        save () {
            this.$axios.post('/api/frontend/city', this.data)
                .then((res) => {
                    if (res.data.success) {
                        this.$store.commit('updateCity', {
                            cityID: this.data.city_id,
                            // cityName: this.cities.filter((c) => c.id === this.data.city_id)[0].name,
                        })
                        this.$store.commit('updateMap', {
                            map: {
                                lat: res.data.data.lat,
                                lng: res.data.data.lng,
                            },
                        })
                        this.$router.push('/goal')
                    } else {
                        this.errors.city_id = res.data.errors.city_id || []
                    }
                })
                .catch(() => {
                })
        },

        setCityID (id) {
            this.data.city_id = id
        },
    },
    components: {
        CitySelect,
        TheHeader,
    },
})
</script>
