<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        scrollable
        content-class="elevation-0"
        transition="scale-transition"
    >
        <div id="app-width">
            <yandex-map
                :settings="settings"
                v-if="latLng"
                :coords="latLng"
                :controls="['geolocationControl', 'searchControl', 'zoomControl']"
                style="width: 100%; height: 100vh"
                zoom="15"
                @click="onClick"
            >
                <ymap-marker
                    marker-id="1"
                    :coords="latLng"
                    marker-type="placemark"
                    :icon="{color: 'red'}"
                />
            </yandex-map>
            <v-btn
                block
                large
                depressed
                rounded
                color="primary"
                @click.stop="$emit('update', latLng)"
            >
                Я здесь
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps'

export default {
    props: {
        value: Boolean,
    },
    data: () => ({
        latLng: null,
        settings: {
            apiKey: '7f037fcc-21f0-4c1b-ad6e-1f292ef45a2f', // It's random API key
            lang: 'ru_RU',
            coordorder: 'latlong',
            enterprise: false,
            version: '2.1'
        },
    }),
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        onClick (e) {
            this.latLng = e.get('coords')
        },
    },
    async mounted () {
        await loadYmap({ ...this.settings, debug: true })

        window.ymaps.geolocation.get().then(res => {
            this.latLng = res.geoObjects.position
        })
    },
    components: {
        yandexMap,
        ymapMarker,
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    min-height: 100%;
    border: none;
}
.v-btn {
    min-width: calc(100% - 48px) !important;
    position: absolute;
    right: 24px;
    bottom: 40px;
    left: 24px;
}
</style>
