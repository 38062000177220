var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app-width"}},[_c('the-header',{attrs:{"anonymous":true,"data-image":{
            fileName: 'icons/bear.svg',
            height: '126',
            hasBackground: true
        }}}),_c('v-container',{staticClass:"flex-container justify-center text-center",attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-auto mb-8"},[_vm._v("Ваш пол")]),_c('v-item-group',{staticClass:"mb-16",attrs:{"mandatory":""}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.genders),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"block":"","depressed":"","color":_vm.data.gender === item.value ? 'primary' : 'accent'},on:{"click":function($event){_vm.data.gender = item.value; toggle()}}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})],1)}),1)],1),_c('h2',{staticClass:"mb-8"},[_vm._v("Вы в поиске")]),_c('v-item-group',{staticClass:"mb-16",attrs:{"mandatory":""}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.targets),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"block":"","depressed":"","color":_vm.data.target === item.value ? 'primary' : 'accent'},on:{"click":function($event){_vm.data.target = item.value; toggle()}}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})],1)}),1)],1),_c('h2',{staticClass:"mb-8"},[_vm._v(" Возрастом от "+_vm._s(_vm.data.age[0])+" до "+_vm._s(_vm.data.age[1])+" ")]),_c('app-slider',{staticClass:"flex-grow-0 mb-12",attrs:{"min":_vm.age.min,"max":_vm.age.max,"range":true},on:{"action":_vm.setAge},model:{value:(_vm.data.age),callback:function ($$v) {_vm.$set(_vm.data, "age", $$v)},expression:"data.age"}}),_c('v-row',{staticClass:"flex-grow-0 mt-auto",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","large":"","depressed":"","rounded":"","color":"secondary","to":"/"}},[_vm._v(" Назад ")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"block":"","large":"","depressed":"","rounded":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" Указать город ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }