<template>
    <div id="app-width">
        <the-header
            :anonymous="false"
        />
        <yandex-map
            ref="map"
            :settings="settings"
            :coords="latLng"
            :controls="['geolocationControl', 'searchControl', 'zoomControl']"
            :detailed-controls="detailedControls"
            @map-was-initialized="init"
            @boundschange="update"
            style="width: 100%; height: calc(100vh - 34px);"
            zoom="11"
        >
            <ymap-marker
                marker-id="0"
                :coords="userLatLng"
                marker-type="placemark"
                :icon="locationIcon"
            />
            <ymap-marker
                v-for="i in markers"
                :key="i.id"
                :marker-id="i.id"
                :coords="i.latLng"
                marker-type="placemark"
                :icon="i.id === markerActive ? markerIconActive : markerIcon"
                @click="markerOnClick(i.id)"
            />
        </yandex-map>
        <user-offer
            :key="offer.id"
            :id="offer.id"
            :age="offer.age"
            :text="offer.message"
            :hasReply="false"
            style="
                position: absolute;
                margin: 0 !important;
                right: 6px;
                bottom: 6px;
                left: 6px;
                z-index: 5;
            "
            v-if="markerActive > 0"
        />
    </div>
</template>

<script>
import TheHeader from '@/components/app/TheHeader'
import UserOffer from '@/components/UserOffer'
import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps'

export default ({
    name: 'MapController',
    data: () => ({
        data: {
        },
        map: null,
        offer: {
            id: 0,
            age: 0,
            message: '',
        },
        offers: [],
        settings: {
            apiKey: '7f037fcc-21f0-4c1b-ad6e-1f292ef45a2f', // It's random API key
            lang: 'ru_RU',
            coordorder: 'latlong',
            enterprise: false,
            version: '2.1',
        },
        detailedControls: {
            zoomControl: {
                position: {
                    top: 120,
                    left: 10,
                },
            },
            geolocationControl: {
                position: {
                    top: 30,
                    left: 10
                },
            },
            searchControl: {
                size: 'large',
                fitMaxWidth: true,
                maxWidth: [30, 72, 300],
                position: {
                    top: 30,
                    right: 10,
                },
            },
        },
        locationIcon: {
            layout: 'default#imageWithContent',
            imageSize: [40, 40],
            imageOffset: [-20, -20],
            content: 'content',
            contentLayout: '<div class="placemark_location_layout"></div>',
        },
        markerIcon: {
            layout: 'default#imageWithContent',
            imageSize: [20, 20],
            imageOffset: [-10, -10],
            content: 'content',
            contentLayout: '<div class="placemark_circle_layout"></div>',
        },
        markerIconActive: {
            layout: 'default#imageWithContent',
            imageSize: [28, 28],
            imageOffset: [-14, -14],
            content: 'content',
            contentLayout: '<div class="placemark_circle_layout active"></div>',
        },
        markerActive: 0,
        markers: [],
        userLatLng: null,
    }),
    methods: {
        markerOnClick (id) {
            if (this.markerActive === id) {
                this.markerActive = 0
            } else {
                this.markerActive = id
                this.offer = this.offers.filter((o) => o.id === id)[0]
            }
        },

        init (map) {
            this.map = map
            this.search()
        },

        search () {
            this.$axios.post('/api/frontend/offer/search/map', {map: this.map.getBounds()})
                .then((res) => {
                    if (res.data.success) {
                        this.offers = res.data.data
                        this.markers = res.data.data.map((o) => ({
                            id: o.id,
                            latLng: [o.lat, o.lng],
                        }))
                    }
                })
                .catch(() => {
                })
        },

        update () {
            this.search()
        },
    },
    computed: {
        latLng () {
            return [
                parseFloat(this.$store.getters.user.mapLat || 55.7),
                parseFloat(this.$store.getters.user.mapLng || 37.6),
            ]
        },
    },
    watch: {
        latLng () {
            return [
                parseFloat(this.$store.getters.user.mapLat || 55.7),
                parseFloat(this.$store.getters.user.mapLng || 37.6),
            ]
        },
    },
    async mounted () {
        await loadYmap(this.settings)

        window.ymaps.geolocation.get().then(res => {
            this.userLatLng = res.geoObjects.position
        })
    },
    components: {
        TheHeader,
        UserOffer,
        yandexMap,
        ymapMarker,
    },
})
</script>

<style lang="scss" scoped>
.dating-header {
    z-index: 3;
}
.ymap-container {
    margin-top: -24px;
    z-index: 1;
}
</style>

<style lang="scss">
.placemark_circle_layout {
    background-color: #FFFFFF;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    left: 0px;
    border: 5px solid #6A7E85;
    border-radius: 50px;
    box-sizing: border-box;

    &.active {
        width: 28px;
        height: 28px;
        border: 8px solid var(--v-primary);
    }
}

.placemark_location_layout {
    background-color: #2D2D2D;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 0px;
    border-radius: 50px;
    box-sizing: border-box;

    &::after {
        content: '';
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNiAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjExNzY1IDAuNjU0NDEyQzcuNDk0MTIgLTAuMDUxNDcwOCA4LjUwNTg4IC0wLjA1MTQ3MDYgOC44ODIzNSAwLjY1NDQxMkwxNC45MzI3IDExLjk5ODdDMTUuMzQxOCAxMi43NjU4IDE0LjY0NDggMTMuNjUzNSAxMy44MDI2IDEzLjQzODJMOC4yNDc3MSAxMi4wMTc5QzguMDg1MTkgMTEuOTc2MyA3LjkxNDgxIDExLjk3NjMgNy43NTIyOSAxMi4wMTc5TDIuMTk3NCAxMy40MzgyQzEuMzU1MTkgMTMuNjUzNSAwLjY1ODI1IDEyLjc2NTggMS4wNjczNCAxMS45OTg3TDcuMTE3NjUgMC42NTQ0MTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=');
        display: block;
        width: 16px;
        height: 14px;
    }
}
</style>
