<template>
    <v-range-slider
        v-if="range"
        v-model="slider"
        :min="min"
        :max="max"
        hide-details
        thumb-color="#FFFFFF"
        track-color="#BAC6CA"
        @input="$emit('action', slider)"
    />
    <v-slider
        v-else
        v-model="slider"
        :min="min"
        :max="max"
        hide-details
        thumb-color="#FFFFFF"
        track-color="#BAC6CA"
        @input="$emit('action', slider)"
    />
</template>

<script>
export default {
    emits: ['action'],
    props: {
        value: [Array, Number],
        min: Number,
        max: Number,
        range: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            slider: this.value,
        }
    },
}
</script>
