<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        scrollable
        content-class="elevation-0"
        transition="scale-transition"
    >
        <div id="app-width">
            <v-card
                tile
                outlined
                color="#f5f9fb"
            >
                <v-toolbar
                    flat
                    height="58"
                    color="surface"
                    class="flex-grow-0"
                >
                    <v-toolbar-title>Параметры поиска</v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon @click.stop="show = false">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
                            <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
                        </svg>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="flex-container">
                    <app-alert
                        v-if="emailConfirmed"
                        class="text-center mt-n6"
                        color="blue-grey lighten-5"
                    >
                        {{ email }} – адрес для ответов
                    </app-alert>
                    <h3 class="mb-4">Ваш пол</h3>
                    <v-item-group
                        class="mb-8"
                    >
                        <v-row>
                            <v-col
                                v-for="(item, i) in genders"
                                :key="i"
                                cols="4"
                            >
                                <v-item v-slot="{ toggle }">
                                    <v-btn
                                        block
                                        depressed
                                        :color="data.gender === item.value ? 'primary' : 'accent'"
                                        @click="data.gender = item.value; toggle()"
                                    >
                                        {{ item.label }}
                                    </v-btn>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                    <h3 class="mb-4">Вы ищете</h3>
                    <v-item-group
                        mandatory
                        class="mb-8"
                    >
                        <v-row justify="center">
                            <v-col
                                v-for="(item, i) in targets"
                                :key="i"
                                cols="4"
                            >
                                <v-item v-slot="{ toggle }">
                                    <v-btn
                                        block
                                        depressed
                                        :color="data.target === item.value ? 'primary' : 'accent'"
                                        @click="data.target = item.value; toggle()"
                                    >
                                        {{ item.label }}
                                    </v-btn>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                    <h3 class="mb-4">
                        В возрасте от {{ data.age[0] }} до {{ data.age[1] }} лет
                    </h3>
                    <app-slider
                        v-model="data.age"
                        @action="setAge"
                        :min="age.min"
                        :max="age.max"
                        :range="true"
                        class="mb-4"
                    />

                    <h3 class="mb-4">
                        Цель знакомства
                    </h3>
                    <div class="mb-8">
                        <goal-select
                            v-model="data.goal_id"
                            :errors="errors.goal_id"
                            @action="setGoalID"
                        />
                    </div>

                    <h3 class="mb-4">
                        Город
                    </h3>
                    <div class="mb-8">
                        <city-select
                            v-model="data.city_id"
                            :errors="errors.city_id"
                            @action="setCityID"
                        />
                    </div>
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click="save"
                    >
                        Сохранить
                    </v-btn>
                    <v-layout class="mt-11" justify-space-between>
                        <a class="text-caption text-decoration-underline mx-3" @click.stop="userAgreementDialogOpen = true">Соглашение</a>
                        <a class="text-caption text-decoration-underline mx-3" @click.stop="privacyPolicyDialogOpen = true">Конфиденциальность</a>
                        <a class="text-caption text-decoration-underline ml-3" @click.stop="supportDialogOpen = true">Поддержка</a>
                    </v-layout>
                </v-card-text>
            </v-card>
        </div>
        <UserAgreementModal v-model="userAgreementDialogOpen"/>
        <PrivacyPolicyModal v-model="privacyPolicyDialogOpen"/>
        <SupportModal v-model="supportDialogOpen"/>
    </v-dialog>
</template>

<script>
import AppAlert from '../app/AppAlert'
import AppSlider from '../app/AppSlider.vue'
import CitySelect from '@/components/app/CitySelect'
import GoalSelect from '@/components/app/GoalSelect'
import PrivacyPolicyModal from './PrivacyPolicyModal'
import SupportModal from './SupportModal'
import UserAgreementModal from './UserAgreementModal'

export default {
    props: {
        value: Boolean,
    },
    data: () => ({
        data: {
            age: [18, 40],
            city_id: 0,
            gender: 'female',
            goal_id: 0,
            target: 'male',
        },
        errors: {
            city_id: [],
            goal_id: [],
        },
        age: {
            min: 18,
            max: 60,
        },
        email: '',
        emailConfirmed: false,
        genders: [
            {
                label: 'Женщина',
                value: 'female',
            },
            {
                label: 'Мужчина',
                value: 'male',
            },
        ],
        targets: [
            {
                label: 'Женщины',
                value: 'female',
            },
            {
                label: 'Мужчины',
                value: 'male',
            },
            {
                label: 'Пары',
                value: 'couple',
            }
        ],
        supportDialogOpen: false,
        privacyPolicyDialogOpen: false,
        userAgreementDialogOpen: false,
    }),
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        show (visible) {
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
            document.getElementById('app').style.paddingRight = visible ? `${scrollBarWidth}px` : '0'
        }
    },
    created () {
        this.data.age[0] = this.$store.getters.user.ageMin || 18
        this.data.age[1] = this.$store.getters.user.ageMax || 40
        this.data.city_id = parseInt(this.$store.getters.user.cityID || 0)
        this.email = this.$store.getters.user.email || ''
        this.emailConfirmed = this.$store.getters.user.emailConfirmed || false
        this.data.gender = this.$store.getters.user.gender || 'female'
        this.data.goal_id = parseInt(this.$store.getters.user.goalID || 0)
        this.data.target = this.$store.getters.user.target || 'male'
    },
    methods: {
        save () {
            this.$axios.post('/api/frontend/config/full', this.data)
                .then((res) => {
                    if (res.data.success) {
                        this.$store.commit('updateCity', {
                            cityID: this.data.city_id,
                            // cityName: this.cities.filter((c) => c.id === this.data.city_id)[0].name,
                        })
                        this.$store.commit('updateConfig', {
                            gender: this.data.gender,
                            target: this.data.target,
                            age: {
                                min: this.data.age[0],
                                max: this.data.age[1],
                            },
                        })
                        this.$store.commit('updateGoal', {
                            goalID: this.data.goal_id,
                            // goalName: this.goals.filter((g) => g.id === this.data.goal_id)[0].name,
                        })
                        this.$store.commit('updateMap', {
                            map: {
                                lat: res.data.data.lat,
                                lng: res.data.data.lng,
                            },
                        })
                        this.show = false
                        this.$emit('update')
                    }
                })
                .catch(() => {
                })
        },

        setAge (age) {
            this.data.age = age
            return this.data.age
        },

        setCityID (id) {
            this.data.city_id = id
        },

        setGoalID (id) {
            this.data.goal_id = id
        },
    },
    components: {
        AppAlert,
        AppSlider,
        CitySelect,
        GoalSelect,
        SupportModal,
        PrivacyPolicyModal,
        UserAgreementModal,
    },
}
</script>

<style lang="scss" scoped>
.v-card {
    min-height: 100%;
    border: none;
}
.v-toolbar {
    border-radius: 0px 0px 32px 32px !important;

    &__title {
        font-weight: 600;
        letter-spacing: -0.3px;
    }
}
h3 {
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -0.3px;
}
</style>
