<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        scrollable
        content-class="elevation-0"
        transition="scale-transition"
    >
        <div id="app-width">
            <v-card
                tile
                outlined
                color="#f5f9fb"
            >
                <v-toolbar
                    flat
                    height="auto"
                    color="surface"
                    class="flex-grow-0"
                >
                    <v-toolbar-title>Обращение в поддержку</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.stop="show = false">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
                            <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
                        </svg>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="flex-container">
                    <v-form
                        ref="form"
                        v-model="support"
                        lazy-validation
                    >
                        <h3 class="mb-4">Ваше имя</h3>
                        <v-text-field
                            name="name"
                            :rules="[rules.name]"
                            required
                            color="blue-grey darken-1"
                            class="mb-8 flex-grow-0"
                            dense solo flat
                            outlined
                            hide-details
                            label="Ваше имя"
                        ></v-text-field>
                        <h3 class="mb-4">Электронная почта</h3>
                        <v-text-field
                            name="email"
                            :rules="[rules.email]"
                            required
                            color="blue-grey darken-1"
                            class="mb-8 flex-grow-0"
                            dense solo flat
                            outlined
                            hide-details
                            label="Ваша электронная почта"
                        ></v-text-field>
                        <v-textarea
                            name="support_text"
                            :rules="[rules.text]"
                            required
                            color="blue-grey darken-1"
                            class="mb-0 flex-grow-0"
                            dense solo flat
                            outlined
                            no-resize
                            height="156"
                            placeholder="Опишите подробно проблему и какие шаги приводят к ее повторению. Обратите внимание, что поддержка осуществляется только на Английском и Русском языках"
                        ></v-textarea>
                        <v-btn
                            block
                            large
                            depressed
                            rounded
                            color="primary"
                            class="flex-grow-0"
                        >
                            Отправить
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean
    },
    data: () => ({
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Недопустимое значение'
            },
            name: value => (value && value.length >= 3 && value.length <= 30) || 'Минимум 3 символа, максимум - 30',
            text: value => (value && value.length >= 20 && value.length <= 1500) || 'Минимум 20 символов, максимум - 1500',
        },
        support: true,
    }),
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        show (visible) {
            if (!visible) {
                this.$refs.form.resetValidation()
                this.$nextTick(() => {
                    if(document.querySelectorAll('.v-dialog--active').length) {
                        document.documentElement.classList.add('overflow-y-hidden')
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.v-card {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border: none;
}
.v-toolbar {
    border-radius: 0px 0px 32px 32px !important;

    &__title {
        font-weight: 600;
        letter-spacing: -0.3px;
    }
}
h3 {
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -0.3px;
}
</style>
