<template>
    <router-link
        class="offer text-decoration-none mb-4"
        :class="moderation ? 'offer--moderation' : ''"
        :to="'/offer/' + id"
    >
        <img
            v-if="hasReply"
            :src="require('../assets/images/icons/reply.svg')"
            class="offer__reply mr-4"
            height="14"
        />
        <app-chip class="min-w-50">
            {{ age }}
            {{ ageText }}
        </app-chip>
        {{ text }}
    </router-link>
</template>

<script>
import AppChip from './app/AppChip.vue'

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        age: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        moderation: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasReply: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: () => ({
        declension: ['год', 'года', 'лет'],
    }),
    methods: {
        //
    },
    computed: {
        ageText () {
            const cases = [2, 0, 1, 1, 1, 2]
            return this.declension[(this.age % 100 > 4 && this.age % 100 < 20) ? 2 : cases[(this.age % 10 < 5) ? this.age % 10 : 5]]
        },
    },
    components: {
        AppChip,
    },
}
</script>

<style lang="scss" scoped>
.offer {
    background: map-get($material-theme, 'surface');
    display: block;
    padding: 8px;
    padding-left: 12px;
    border-radius: 10px;
    color: map-get($material-theme, 'text', 'primary');
    font-size: 0.875em;
    line-height: 1.286;
    transition: background .2s;

    &:hover {
        background: darken(map-get($material-theme, 'surface'), 1.25%);
        cursor: pointer;
    }
    &--moderation {
        position: relative;

        &::before {
            content: '';
            background-color: rgba(45, 45, 45, .7);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: inherit;
            z-index: 1;
        }
        &::after {
            content: 'На модерации';
            background: var(--v-primary);
            position: absolute;
            display: inline-block;
            padding-right: 7px;
            padding-left: 7px;
            top: 50%;
            left: 50%;
            border-radius: 4px;
            transform: translate(-50%, -50%);
            color: #FFF;
            font-size: 13px;
            font-weight: 500;
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.3px;
            z-index: 2;
        }
    }
    .v-chip {
        margin-right: 3px;
        margin-left: -2px;
    }
    &__reply {
        vertical-align: text-bottom;
        margin-bottom: 1px;
    }
}
</style>
