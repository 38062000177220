<template>
    <div id="app-width">
        <v-card
            tile
            outlined
            color="background"
        >
            <v-toolbar
                flat
                height="auto"
                color="surface"
                class="flex-grow-0"
            >
                <div class="offer">
                    <div class="offer__header">
                        <v-toolbar-title>Ответ на объявление</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon to="/list">
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
                                <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
                            </svg>
                        </v-btn>
                    </div>
                    <p class="offer__location">{{ offer.city }}</p>
                    <p class="offer__text">{{ offer.message }}</p>
                    <div class="offer__chips">
                        <app-chip class="mr-2" color="rgba(255, 116, 116, 0.1)" text-color="primary">{{ offer.goal }}</app-chip>
                        <app-chip class="mr-2">{{ offer.age }} {{ ageText }}</app-chip>
                        <app-chip>
                            <span v-if="offer.target === 'male'">Ищу мужчину</span>
                            <span v-else-if="offer.target === 'female'">Ищу женщину</span>
                            <span v-else>Ищу пару</span>
                        </app-chip>
                    </div>
                </div>
            </v-toolbar>
            <v-card-text class="flex-container">
                <v-textarea
                    v-model="data.message"
                    :error-messages="errors.message"
                    :rules="[rules.text]"
                    required
                    color="blue-grey darken-1"
                    class="mb-8"
                    dense solo flat
                    outlined
                    no-resize
                    hide-details
                    height="106"
                    name="offer_text"
                    placeholder="Текст вашего объявления, не менее 10 и не более 1000 символов"
                ></v-textarea>
                <v-text-field
                    v-model="data.email"
                    append-icon="$mail"
                    color="blue-grey darken-1"
                    class="icon--mail"
                    :error-messages="errors.email"
                    :rules="[rules.email]"
                    required
                    :disabled="emailConfirmed"
                    dense solo flat
                    outlined
                    hide-details
                    label="Ваша электронная почта"
                ></v-text-field>
                <p class="text-caption mt-2 mb-12" v-if="!isAuthenticated">На этот адрес будут приходить ответы. <br /> Её потребуется подтвердить.</p>
                <v-btn
                    block
                    large
                    depressed
                    rounded
                    color="primary"
                    @click="save"
                >
                    Отправить ответ
                </v-btn>
                <p class="text-caption mt-2 mb-12">Отправляя ответ, вы подтверждаете что вам более 18 лет и вы ознакомились и согласились с <a class="text-decoration-underline" @click.stop="userAgreementDialogOpen = true">пользовательским соглашением.</a></p>
            </v-card-text>
        </v-card>
        <UserAgreementModal v-model="userAgreementDialogOpen" />
    </div>
</template>

<script>
import AppChip from '@/components/app/AppChip'
import UserAgreementModal from '@/components/modals/UserAgreementModal'

export default {
    name: 'OfferController',
    data: () => ({
        data: {
            email: '',
            message: '',
        },
        declension: ['год', 'года', 'лет'],
        emailConfirmed: false,
        errors: {
            email: [],
            message: [],
        },
        offer: {},
        email: '',
        rules: {
            text: value => (value && value.length >= 10 && value.length <= 1000) || 'Минимум 10 символов, максимум - 1000',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Недопустимое значение'
            },
        },
        userAgreementDialogOpen: false,
    }),
    created () {
        this.search()
        this.data.email = this.$store.getters.user.email || ''
        this.emailConfirmed = this.$store.getters.user.emailConfirmed || false
    },
    computed: {
        ageText () {
            const cases = [2, 0, 1, 1, 1, 2]
            return this.declension[(this.age % 100 > 4 && this.age % 100 < 20) ? 2 : cases[(this.age % 10 < 5) ? this.age % 10 : 5]]
        },
        isAuthenticated () {
            return this.$store.getters.isAuthenticated
        },
    },
    methods: {
        save () {
            this.$axios.post('/api/frontend/offer/reply', {offer_id: this.$route.params.id, ...this.data})
                .then((res) => {
                    if (res.data.success) {
                        this.$store.commit('updateEmail', {
                            email: this.data.email,
                        })
                        this.$router.go(-1)
                    } else {
                        this.errors.email = res.data.errors.email || []
                        this.errors.message = res.data.errors.message || []
                    }
                })
                .catch(() => {
                })
        },

        search () {
            this.$axios.post('/api/frontend/offer/view/' + this.$route.params.id)
                .then((res) => {
                    if (res.data.success) {
                        this.offer = res.data.data
                    }
                })
                .catch(() => {
                })
        },
    },
    components: {
        AppChip,
        UserAgreementModal,
    },
}
</script>

<style lang="scss" scoped>
.v-card {
    min-height: 100%;
    border: none;
}
.v-toolbar {
    border-radius: 0px 0px 32px 32px !important;

    &__title {
        font-weight: 600;
        letter-spacing: -0.3px;
    }
}
.v-btn {
    &--active {
        &::before {
            opacity: 0;
        }
    }
}
.offer {
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    &__location {
        margin: 6px 0 4px;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.3px;
        color: #99A5A9;
    }
    &__text {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
    }
    &__chips {
        margin-bottom: 13px;
    }
}
h3 {
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -0.3px;
}
</style>
