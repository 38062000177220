<template>
    <div id="app-width">
        <the-header
            :anonymous="true"
            :data-image="{
                fileName: 'icons/bear.svg',
                height: '126',
                hasBackground: true
            }"
        />
        <v-container fluid class="flex-container justify-center text-center">
            <h2 class="mt-auto mb-8">Ваш пол</h2>
            <v-item-group
                mandatory
                class="mb-16"
            >
                <v-row justify="center">
                    <v-col
                        v-for="(item, i) in genders"
                        :key="i"
                        cols="4"
                    >
                        <v-item v-slot="{ toggle }">
                            <v-btn
                                block
                                depressed
                                :color="data.gender === item.value ? 'primary' : 'accent'"
                                @click="data.gender = item.value; toggle()"
                            >
                                {{ item.label }}
                            </v-btn>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>

            <h2 class="mb-8">Вы в поиске</h2>
            <v-item-group
                mandatory
                class="mb-16"
            >
                <v-row justify="center">
                    <v-col
                        v-for="(item, i) in targets"
                        :key="i"
                        cols="4"
                    >
                        <v-item v-slot="{ toggle }">
                            <v-btn
                                block
                                depressed
                                :color="data.target === item.value ? 'primary' : 'accent'"
                                @click="data.target = item.value; toggle()"
                            >
                                {{ item.label }}
                            </v-btn>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>

            <h2 class="mb-8">
                Возрастом от {{ data.age[0] }} до {{ data.age[1] }}
            </h2>
            <app-slider
                v-model="data.age"
                @action="setAge"
                :min="age.min"
                :max="age.max"
                :range="true"
                class="flex-grow-0 mb-12"
            />
            <v-row justify="center" class="flex-grow-0 mt-auto">
                <v-col cols="4">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="secondary"
                        to="/"
                    >
                        Назад
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click="save"
                    >
                        Указать город
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AppSlider from '../app/AppSlider'
import TheHeader from '@/components/app/TheHeader'

export default ({
    name: 'ConfigController',
    data: () => ({
        data: {
            age: [18, 40],
            gender: 'female',
            target: 'male',
        },
        age: {
            min: 18,
            max: 60,
        },
        genders: [
            {
                label: 'Женщина',
                value: 'female',
            },
            {
                label: 'Мужчина',
                value: 'male',
            },
        ],
        targets: [
            {
                label: 'Женщины',
                value: 'female',
            },
            {
                label: 'Мужчины',
                value: 'male',
            },
            {
                label: 'Пары',
                value: 'couple',
            }
        ],
    }),
    created () {
        this.data.age[0] = this.$store.getters.user.ageMin || 18
        this.data.age[1] = this.$store.getters.user.ageMax || 40
        this.data.gender = this.$store.getters.user.gender || 'female'
        this.data.target = this.$store.getters.user.target || 'male'
    },
    methods: {
        setAge (age) {
            this.data.age = age
            return this.data.age
        },

        save () {
            this.$axios.post('/api/frontend/config', this.data)
                .then((res) => {
                    if (res.data.success) {
                        this.$store.commit('updateConfig', {
                            gender: this.data.gender,
                            target: this.data.target,
                            age: {
                                min: this.data.age[0],
                                max: this.data.age[1],
                            },
                        })
                        this.$router.push('/city')
                    }
                })
                .catch(() => {
                })
        },
    },
    components: {
        AppSlider,
        TheHeader,
    },
})
</script>
