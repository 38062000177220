<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        scrollable
        content-class="elevation-0"
        transition="scale-transition"
    >
        <div id="app-width">
            <v-card
                tile
                outlined
                color="#f5f9fb"
            >
                <v-toolbar
                    flat
                    height="auto"
                    color="surface"
                    class="flex-grow-0"
                >
                    <v-toolbar-title>Политика конфиденциальности</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.stop="show = false">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
                            <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
                        </svg>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="flex-container">
                    <b>Политика о данных пользователей Сервиса «Анонимные знакомства»</b>
                    <p>Эта политика описывает, как ЗАО «Мамба» («компания» или «мы») обрабатывает и защищает персональные данные пользователей Сервиса «Анонимные знакомства» (далее – «Сервис»).</p>
                    <p>Политика применяется как ко всем пользователям Сервиса («пользователи» или «вы»). Политика применяется не зависимо от устройства, с которого вы используете Сервис.</p>
                    <b>Предоставляя нам персональные данные, вы даете согласие на их обработку в соответствии с этой политикой.</b>
                    <p>
                        1. Определения <br/>
                        Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
                    </p>
                    <p>Обработка персональных данных – осуществление любых действий или совокупности действий в отношении персональных данных, включая сбор, запись, систематизацию, накопление, хранение, обновление и изменение, извлечение, использование, передачу (распространение, предоставление, доступ), блокирование, удаление и уничтожение – как с использованием, так и без использования средств автоматизированной обработки данных.</p>
                </v-card-text>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean
    },
    data: () => ({
        //
    }),
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        show (visible) {
            this.$nextTick(() => {
                if(!visible && document.querySelectorAll('.v-dialog--active').length) {
                    document.documentElement.classList.add('overflow-y-hidden')
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .v-card {
        min-height: 100%;
        border: none;
    }
    .v-toolbar {
        border-radius: 0px 0px 32px 32px !important;

        &__title {
            margin: 7px 0 7px;
            white-space: normal;
            font-weight: 600;
            letter-spacing: -0.3px;
        }
        .v-btn {
            align-self: flex-start;
            margin-top: 2px;
        }
    }
    p, b {
        font-size: 0.9375rem;
        line-height: 1.4;
        margin: 13px 0;

        &:first-child {
            margin-top: 0;
        }
    }
    b {
        font-weight: 600;
    }
</style>
