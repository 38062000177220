import Vue from 'vue'
import VueRouter from 'vue-router'

import CityController from '@/components/controllers/CityController'
import ConfigController from '@/components/controllers/ConfigController'
import DefaultController from '@/components/controllers/DefaultController'
import EmailConfirmController from '@/components/controllers/EmailConfirmController'
import Error404Controller from '@/components/controllers/Error404Controller'
import ForbiddenController from '@/components/controllers/ForbiddenController'
import GoalController from '@/components/controllers/GoalController'
import ListController from '@/components/controllers/ListController'
import MapController from '@/components/controllers/MapController'
import OfferController from '@/components/controllers/OfferController'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'default',
        component: DefaultController,
    },
    {
        path: '/config',
        name: 'config',
        component: ConfigController,
    },
    {
        path: '/city',
        name: 'city',
        component: CityController,
    },
    {
        path: '/goal',
        name: 'goal',
        component: GoalController,
    },
    {
        path: '/no-entry',
        name: 'noEntry',
        component: ForbiddenController,
    },
    {
        path: '/map',
        name: 'Доска объявлений на карте',
        component: MapController,
    },
    {
        path: '/list',
        name: 'list',
        component: ListController,
    },
    {
        path: '/offer/:id',
        name: 'offer',
        component: OfferController,
    },
    {
        path: '/email/confirm/:hash',
        name: 'emailConfirm',
        component: EmailConfirmController,
    },
    {
        path: '/404',
        name: '404 ошибка',
        component: Error404Controller,
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
