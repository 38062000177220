<template>
    <div id="app-width">
        <v-container fluid class="no-entry flex-container justify-center text-center">
            <img :src="require('../../assets/images/icons/heart-key.svg')" alt="Heart key icon" />
            <h1 class="no-entry__title">Только взрослым</h1>
            <p class="no-entry__text">Нам очень жаль, но контент доступен <br /> только совершеннолетним <br /> ;(</p>
            <div class="no-entry__footer">
                Если вы ошиблись в выборе возраста, <br />
                <router-link to="/">
                    попробуйте еще раз
                </router-link>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'ForbiddenController',
}
</script>

<style scoped lang="scss">
.no-entry {
    align-items: center;
}
img {
    margin-bottom: 32px;
}
.no-entry__title {
    font-size: 2rem;
}
.no-entry__text {
    margin-top: 17px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.4;
}
.no-entry__footer {
    margin-top: 100px;
    font-size: 0.8125rem;
    line-height: 1.385;
    letter-spacing: -0.2px;
    color: var(--v-accent);
}
</style>
