<template>
    <v-select
        v-model="goal_id"
        :items="goals"
        item-text="name"
        item-value="id"
        :error-messages="errors"
        menu-props="offset-y"
        append-icon="$chevronDown"
        color="blue-grey darken-1"
        class="icon--chevron-down"
        dense solo flat
        outlined
        hide-details
        @input="$emit('action', goal_id)"
    />
</template>

<script>
export default {
    name: 'GoalSelect',
    emits: ['action'],
    props: {
        value: Number,
        errors: [Array, String],
    },
    data () {
        return {
            goal_id: this.value,
            goals: [],
        }
    },
    mounted () {
        this.searchGoals()
    },
    methods: {
        searchGoals () {
            this.$axios.post('/api/frontend/resource/goal')
                .then((res) => {
                    if (res.data.success) {
                        this.goals = res.data.data
                    }
                })
                .catch(() => {
                })
        },
    }
}
</script>
