<template>
    <v-dialog
        class="dialog"
        v-model="show"
        fullscreen
        hide-overlay
        scrollable
        content-class="elevation-0"
        transition="scale-transition"
    >
        <div id="app-width">
            <v-card
                tile
                outlined
                color="background"
            >
                <v-toolbar
                    flat
                    height="58"
                    color="surface"
                    class="flex-grow-0"
                >
                    <v-toolbar-title>Добавить объявление</v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon @click.stop="show = false">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
                            <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
                        </svg>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="flex-container">
                    <user-offer
                        :key="1"
                        :id="1"
                        :age="55"
                        text="Ищу КАВКАЗЦА!!! обязательно с волосатой спиной и сильными руками!!!!!"
                        :moderation="true"
                        class="mb-8"
                    />
                    <v-textarea
                        v-model="data.message"
                        :error-messages="errors.message"
                        :rules="[rules.text]"
                        required
                        color="blue-grey darken-1"
                        class="mb-8"
                        dense solo flat
                        outlined
                        no-resize
                        hide-details
                        height="106"
                        name="message"
                        placeholder="Текст вашего объявления, не менее 10 и не более 1000 символов"
                    />
                    <h3 class="mb-4">Ваш пол</h3>
                    <v-item-group
                        class="mb-8"
                    >
                        <v-row>
                            <v-col
                                v-for="(item, i) in genders"
                                :key="i"
                                cols="4"
                            >
                                <v-item v-slot="{ toggle }">
                                    <v-btn
                                        block
                                        depressed
                                        :color="data.gender === item.value ? 'primary' : 'accent'"
                                        @click="data.gender = item.value; toggle()"
                                    >
                                        {{ item.label }}
                                    </v-btn>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                    <h3 class="mb-4">Вы ищете</h3>
                    <v-item-group
                        mandatory
                        class="mb-8"
                    >
                        <v-row justify="center">
                            <v-col
                                v-for="(item, i) in targets"
                                :key="i"
                                cols="4"
                            >
                                <v-item v-slot="{ toggle }">
                                    <v-btn
                                        block
                                        depressed
                                        :color="data.target === item.value ? 'primary' : 'accent'"
                                        @click="data.target = item.value; toggle()"
                                    >
                                        {{ item.label }}
                                    </v-btn>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-item-group>

                    <h3 class="mb-4">
                        Ваш возраст {{ data.age }} {{ ageText(data.age) }}
                    </h3>
                    <app-slider
                        v-model="data.age"
                        @action="setAge"
                        :min="age.min"
                        :max="age.max"
                        class="mb-4"
                    />

                    <h3 class="mb-4">
                        Цель знакомства
                    </h3>
                    <div class="mb-8">
                        <goal-select
                            v-model="data.goal_id"
                            :errors="errors.goal_id"
                            @action="setGoalID"
                        />
                    </div>

                    <h3 class="mb-4">
                        Город
                    </h3>
                    <div class="mb-8">
                        <city-select
                            v-model="data.city_id"
                            :errors="errors.city_id"
                            @action="setCityID"
                        />
                    </div>
                    <v-btn
                        class="mb-8"
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click.stop="showMap = true"
                    >
                        Карта
                    </v-btn>
                    <v-text-field
                        v-model="data.email"
                        append-icon="$mail"
                        color="blue-grey darken-1"
                        class="icon--mail"
                        :error-messages="errors.email"
                        :rules="[rules.email]"
                        required
                        :disabled="emailConfirmed"
                        dense solo flat
                        outlined
                        hide-details
                        label="Ваша электронная почта"
                    />
                    <p class="text-caption mt-2 mb-12" v-if="!isAuthenticated">На этот адрес будут приходить ответы. <br> Её потребуется подтвердить.</p>
                    <v-btn
                        block
                        large
                        depressed
                        rounded
                        color="primary"
                        @click="save"
                    >
                        Опубликовать
                    </v-btn>
                    <p class="text-caption mt-2 mb-12">Публикуя объявление, вы подтверждаете что вам более 18 лет и вы ознакомились и согласились с <a class="text-decoration-underline" @click.stop="showAgreement = true">пользовательским соглашением.</a></p>
                </v-card-text>
            </v-card>
        </div>
        <MapModal v-model="showMap" @update="setMap" />
        <UserAgreementModal v-model="showAgreement" />
    </v-dialog>
</template>

<script>
import AppSlider from '../app/AppSlider'
import CitySelect from '@/components/app/CitySelect'
import GoalSelect from '@/components/app/GoalSelect'
import MapModal from './MapModal'
import UserAgreementModal from './UserAgreementModal'
import UserOffer from '@/components/UserOffer'

export default {
    props: {
        value: Boolean,
    },
    data: () => ({
        data: {
            age: 18,
            city_id: 0,
            email: '',
            gender: 'female',
            goal_id: 0,
            map: null,
            message: '',
            target: 'male',
        },
        emailConfirmed: false,
        errors: {
            age: [],
            city_id: [],
            email: [],
            gender: [],
            goal_id: [],
            message: [],
            target: [],
        },
        age: {
            min: 18,
            max: 60,
        },
        genders: [
            {
                label: 'Женщина',
                value: 'female',
            },
            {
                label: 'Мужчина',
                value: 'male',
            },
        ],
        targets: [
            {
                label: 'Женщины',
                value: 'female',
            },
            {
                label: 'Мужчины',
                value: 'male',
            },
            {
                label: 'Пары',
                value: 'couple',
            }
        ],
        declension: ['год', 'года', 'лет'],
        rules: {
            text: value => (value && value.length >= 10 && value.length <= 1000) || 'Минимум 10 символов, максимум - 1000',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Недопустимое значение'
            },
        },
        showMap: false,
        showAgreement: false,
    }),
    computed: {
        isAuthenticated () {
            return this.$store.getters.isAuthenticated
        },
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        show (visible) {
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
            document.getElementById('app').style.paddingRight = visible ? `${scrollBarWidth}px` : '0'
        }
    },
    created () {
        this.data.city_id = parseInt(this.$store.getters.user.cityID || 0)
        this.data.email = this.$store.getters.user.email || ''
        this.data.gender = this.$store.getters.user.gender || 'female'
        this.data.goal_id = parseInt(this.$store.getters.user.goalID || 0)
        this.data.target = this.$store.getters.user.target || 'male'
        this.emailConfirmed = this.$store.getters.user.emailConfirmed || false
    },
    methods: {
        ageText (number) {
            const cases = [2, 0, 1, 1, 1, 2]
            return this.declension[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
        },
        save () {
            this.$axios.post('/api/frontend/offer/create', this.data)
                .then((res) => {
                    if (res.data.success) {
                        this.$store.commit('updateAge', {
                            age: this.data.age,
                        })
                        this.$store.commit('updateEmail', {
                            email: this.data.email,
                        })
                        this.$store.commit('updateMap', {
                            email: this.data.email,
                            map: {
                                lat: this.data.map[0],
                                lng: this.data.map[1],
                            },
                        })
                        this.$emit('update')
                        this.show = false
                    } else {
                        this.errors.age = res.data.errors.age || []
                        this.errors.city_id = res.data.errors.city_id || []
                        this.errors.email = res.data.errors.email || []
                        this.errors.gender = res.data.errors.gender || []
                        this.errors.goal_id = res.data.errors.goal_id || []
                        this.errors.message = res.data.errors.message || []
                        this.errors.target = res.data.errors.target || []
                    }
                })
                .catch(() => {
                })
        },

        setAge (age) {
            this.data.age = age
            return this.data.age
        },

        setCityID (id) {
            this.data.city_id = id
        },

        setGoalID (id) {
            this.data.goal_id = id
        },

        setMap (map) {
            this.data.map = map
            this.showMap = false
        },
    },
    components: {
        AppSlider,
        CitySelect,
        GoalSelect,
        MapModal,
        UserAgreementModal,
        UserOffer,
    },
}
</script>

<style lang="scss" scoped>
.v-card {
    min-height: 100%;
    border: none;
}
.v-toolbar {
    border-radius: 0 0 32px 32px !important;

    &__title {
        font-weight: 600;
        letter-spacing: -0.3px;
    }
}
h3 {
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -0.3px;
}
</style>
