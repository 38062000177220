<template>
    <v-autocomplete
        append-icon="$pointer"
        color="blue-grey darken-1"
        class="icon--pointer icon-transform-disabled"
        v-model="city_id"
        :items="items"
        :error-messages="errors"
        item-text="name"
        item-value="id"
        cache-items
        :search-input.sync="search"
        menu-props="rounded"
        dense solo flat
        outlined
        hide-no-data
        hide-details
        label="Укажите город"
        @input="$emit('action', city_id)"
    />
</template>

<script>
export default {
    name: 'CitySelect',
    emits: ['action'],
    props: {
        value: Number,
        errors: [Array, String],
    },
    data () {
        return {
            city_id: this.value,
            cities: [],
            items: [],
            search: null,
        }
    },
    watch: {
        search (term) {
            this.items = this.cities.filter((c) => {
                return c.name.toLowerCase().indexOf((term || '').toLowerCase()) > -1
            })
        }
    },
    mounted () {
        this.searchCities()
    },
    methods: {
        searchCities () {
            this.$axios.post('/api/frontend/resource/city')
                .then((res) => {
                    if (res.data.success) {
                        this.cities = res.data.data
                        this.items = this.cities
                    }
                })
                .catch(() => {
                })
        },
    }
}
</script>
