<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    scrollable
    content-class="elevation-0"
    transition="scale-transition"
  >
    <div id="app-width">
      <v-card
        tile
        outlined
        color="#f5f9fb"
      >
        <v-toolbar
          flat
          height="auto"
          color="surface"
          class="flex-grow-0"
        >
          <v-toolbar-title>Пользовательское соглашение</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="show = false">
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6317 24.6317C20.148 29.1155 12.852 29.1155 8.36827 24.6317C3.88451 20.148 3.88451 12.852 8.36827 8.36827C12.852 3.88451 20.148 3.88451 24.6317 8.36827C29.1155 12.852 29.1155 20.148 24.6317 24.6317ZM23.9246 23.9246C28.0188 19.8305 28.0188 13.1695 23.9246 9.07538C19.8305 4.98123 13.1695 4.98123 9.07538 9.07538C4.98123 13.1695 4.98123 19.8305 9.07538 23.9246C13.1695 28.0188 19.8305 28.0188 23.9246 23.9246Z" fill="#2D2D2D"/>
              <path d="M11.9038 12.6109L15.7929 16.5L11.9038 20.3891C11.7086 20.5843 11.7086 20.901 11.9038 21.0962C12.099 21.2914 12.4158 21.2914 12.6109 21.0962L16.5 17.2071L20.3891 21.0962C20.5842 21.2914 20.901 21.2914 21.0962 21.0962C21.2914 20.901 21.2914 20.5843 21.0962 20.3891L17.2071 16.5L21.0962 12.6109C21.2914 12.4158 21.2914 12.099 21.0962 11.9038C20.901 11.7086 20.5842 11.7086 20.3891 11.9038L16.5 15.7929L12.6109 11.9038C12.4158 11.7086 12.099 11.7086 11.9038 11.9038C11.7086 12.099 11.7086 12.4158 11.9038 12.6109Z" fill="#2D2D2D"/>
            </svg>
          </v-btn>
        </v-toolbar>
        <v-card-text class="flex-container">
          <b>Политика о данных пользователей Сервиса «Анонимные знакомства»</b>
          <p>Эта политика описывает, как ЗАО «Мамба» («компания» или «мы») обрабатывает и защищает персональные данные пользователей Сервиса «Анонимные знакомства» (далее – «Сервис»).</p>
          <p>Политика применяется как ко всем пользователям Сервиса («пользователи» или «вы»). Политика применяется не зависимо от устройства, с которого вы используете Сервис.</p>
          <p><i>Предоставляя нам персональные данные, вы даете согласие на их обработку в соответствии с этой политикой.</i></p>
          <b>1. Определения</b>
          <p>Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
          <p>Обработка персональных данных – осуществление любых действий или совокупности действий в отношении персональных данных, включая сбор, запись, систематизацию, накопление, хранение, обновление и изменение, извлечение, использование, передачу (распространение, предоставление, доступ), блокирование, удаление и уничтожение – как с использованием, так и без использования средств автоматизированной обработки данных.</p>
          <b>2. Какие данные мы обрабатываем</b>
          <p>Мы обрабатываем персональные и технические данные.</p>
          <p>Вы предоставляете нам персональные данные, когда:</p>
          <ul>
            <li>посещаете сайт Сервиса или используете мобильное приложение Сервиса;</li>
            <li>размещаете объявления в Сервисе;</li>
            <li>отвечаете на объявления в Сервисе;</li>
            <li>пользуетесь платными и бесплатными услугами Сервиса и Компании; подписываетесь на рассылки;</li>
            <li>участвуете в мероприятиях, исследованиях и опросах;</li>
            <li>пишете или звоните нам;</li>
            <li>пользуетесь правами и выполняете обязанности на основании Соглашения об использовании или законодательства.</li>
          </ul>
          <p>Ваше устройство автоматически передает технические данные: информацию, сохраненную в файлах куки (cookies), информацию о браузере и его настройках, дате и времени доступа к Сервису, адресах запрашиваемых страниц, действиях на сайте или в приложении, технических характеристиках устройства, IP-адресе и т.п.</p>

          <b>3. Для чего мы обрабатываем данные</b>
          <p>Мы обрабатываем персональные и технические данные, чтобы:</p>
          <ul>
            <li>предоставлять вам возможность пользоваться Сервисом и услугами компании: размещать объявления, отвечать на них, оплачивать услуги и т.п.;</li>
            <li>обеспечивать стабильную работу и безопасность Сервиса, улучшать пользовательский опыт, качество услуг и маркетинговых мероприятий компании;</li>
            <li>предупреждать и пресекать нарушения законодательства и Соглашения об использовании;</li>
            <li>отвечать на ваши обращения;</li>
            <li>выполнять обязанности, предусмотренные законодательством (например, для бухгалтерского учёта, налоговой отчётности, ответов на запросы государственных органов);</li>
            <li>направлять маркетинговые сообщения о Сервисе и наших партнерах;</li>
            <li>организовывать ваше участие в мероприятиях, исследованиях и опросах компании и наших партнеров;</li>
          </ul>
          <p>и для других целей с вашего согласия.</p>

          <b>4. Правовые основания обработки</b>
          <p>Мы обрабатываем ваши персональные данные:</p>
          <ul>
            <li>с вашего согласия;</li>
            <li>для заключения, исполнения, изменения или прекращения договора – Соглашения об использовании;</li>
            <li>для осуществления прав и законных интересов компании или третьих лиц, если при этом не нарушаются ваши права и свободы;</li>
            <li>для выполнения обязанностей, возложенных на компанию законодательством;</li>
            <li>если данные общедоступные (в открытом доступе).</li>
          </ul>
          <p>Мы не обрабатываем специальные категории персональных данных. Мы не обрабатываем биометрические персональные данные для установления личности.</p>

          <b>5. Передача третьим лицам. Трансграничная передача</b>
          <p>Мы можем передавать персональные данные или поручать их обработку третьим лицам.</p>
          <p>Мы передаем персональные данные только в те страны, которые обеспечивают их адекватную защиту.</p>
          <p>Мы никогда не передадим ваши данные спамерам.</p>

          <b>6. Сервис – открытый источник данных</b>
          <p>Относитесь ответственно к размещению информации в интернете. Ваши объявления, сможет увидеть любой пользователь интернета, зайдя в Сервис.</p>
          <p>Цель, с которой пользователи размещают данные в Сервисе – установить контакт с другим человеком, который заинтересован в знакомстве. Запрещено обрабатывать данные пользователей для любых других целей. Нельзя писать пользователям, чтобы предлагать им свои услуги. Нельзя копировать данные пользователей, чтобы размещать и хранить их на других сервисах. Нельзя использовать открытые данные для скоринга. Все это – незаконно.</p>

          <b>7. Безопасность</b>
          <p>Для защиты персональных данных, мы:</p>
          <ul>
            <li>Регулярно проверяем процессы и документы компании на их соответствие закону.</li>
            <li>Оцениваем риски и вред, который можем причинить, если нарушим закон о персональных данных. С учетом проведенной оценки подбираем подходящие меры для соблюдения закона.</li>
            <li>Применяем правовые, организационные и технические меры, чтобы обеспечить безопасность персональных данных.</li>
          </ul>
          <p>Принимая меры по защите персональных данных, мы основываемся на:</p>
          <ul>
            <li>требованиях законодательства;</li>
            <li>установленном уровне защищенности персональных данных;</li>
            <li>актуальных угрозах, определенных моделью угроз;</li>
            <li>базовом наборе мер защиты, установленном нормативными правовыми актами для соответствующего уровня защищенности;</li>
            <li>риск-ориентированном подходе при выборе оптимальных мер;</li>
            <li>приоритете законных интересов пользователей.</li>
          </ul>

          <b>8. Сроки обработки</b>
          <p>Мы прекращаем обработку ваших персональных данных:</p>
          <ul>
            <li>по истечении срока согласия или при отзыве согласия (если нет других оснований для обработки, предусмотренных законодательством). Вы даете согласие на 5 лет с даты удаления вашего объявления в Сервисе или направления Вашего ответа на объявление. Другой срок может быть предусмотрен в отдельных случаях;</li>
            <li>по достижении целей обработки либо при утрате необходимости в их достижении (если нет других оснований для обработки, предусмотренных законодательством);</li>
            <li>при выявлении неправомерной обработки, если обеспечить правомерность невозможно;</li>
            <li>при ликвидации компании.</li>
          </ul>

          <b>9. Связаться с нами</b>
          <p>Чтобы задать вопрос или отозвать согласие на обработку персональных данных, обратитесь в службу поддержки.</p>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  data: () => ({
    //
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show (visible) {
      this.$nextTick(() => {
        if(!visible && document.querySelectorAll('.v-dialog--active').length) {
          document.documentElement.classList.add('overflow-y-hidden')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-card {
    min-height: 100%;
    border: none;
  }
  .v-toolbar {
    border-radius: 0px 0px 32px 32px !important;

    &__title {
      margin: 7px 0 7px;
      white-space: normal;
      font-weight: 600;
      letter-spacing: -0.3px;
    }
    .v-btn {
      align-self: flex-start;
      margin-top: 2px;
    }
  }
  p, b {
    font-size: 0.9375rem;
    line-height: 1.4;
    margin: 13px 0;

    &:first-child {
      margin-top: 0;
    }
  }
  b {
    font-weight: 600;
  }
</style>
