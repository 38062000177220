<template>
    <div id="app-width">
        <v-container fluid class="page404 flex-container justify-center text-center">
            <img :src="require('../../assets/images/icons/heart-broken.svg')" alt="Broken heart icon" />
            <h1 class="page404__title">404 ошибка</h1>
            <p class="page404__text">Надеемся, отсутствие страницы <br /> не разобъет ваше сердце!</p>
            <v-btn
                class="page404__btn flex-grow-0"
                block
                large
                depressed
                rounded
                color="primary"
                to="/list"
            >
                Перейти в каталог
            </v-btn>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Error404Controller',
}
</script>

<style scoped lang="scss">
.page404 {
    align-items: center;
}
img {
    margin-bottom: 42px;
}
.page404__title {
    font-size: 2rem;
}
.page404__text {
    margin-top: 17px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.4;
}
.page404__btn {
    margin-top: 115px;
}
</style>
