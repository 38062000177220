const state = {
    token: localStorage.getItem('auth-token') || '',
}

const getters = {
    isAuthenticated: state => !!state.token,
}

const mutations = {
    removeToken (state) {
        localStorage.removeItem('auth-token')
        state.token = ''
    },
    updateToken (state, token) {
        localStorage.setItem('auth-token', token)
        state.token = token
    },
}

export default {
    state,
    getters,
    mutations,
}
