<template>
  <v-chip
    x-small
    :color="color"
    :text-color="textColor"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#EBF0F3'
    },
    textColor: {
      type: String,
      default: '#99A5A9'
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-chip {
    justify-content: center;
    align-items: center;
    padding-right: 6.5px;
    padding-left: 6.5px;
    vertical-align: text-bottom;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 11px;
    letter-spacing: -0.3px;
    text-align: center;

    &.min-w-50 {
      min-width: 50px;
    }
  }
</style>
