<template>
  <v-alert :color="color">
    <slot />
  </v-alert>
</template>

<script>
export default ({
  name: 'AppAlert',
  props: {
    color: String
  }
})
</script>

<style lang="scss" scoped>
  .v-alert {
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 10px !important;
    font-size: 0.75rem;
  }
</style>
