<template>
    <div id="app-width">
        <the-header
            :anonymous="false"
            @update="search"
        />
        <v-container fluid class="flex-container">
            <app-alert
                v-if="emailConfirmed"
                class="text-center"
                color="blue-grey lighten-5"
            >
                {{ email }} – адрес для ответов
            </app-alert>
            <div
                v-for="(data, day) in offers"
                :key="day"
                class="mb-12"
            >
                <h4 class="offers-timestamp mb-4" v-if="$moment().format('YYYY-MM-DD') === day">Сегодня</h4>
                <h4 class="offers-timestamp mb-4" v-else-if="$moment().subtract(1, 'days').format('YYYY-MM-DD') === day">Вчера</h4>
                <h4 class="offers-timestamp mb-4" v-else>{{ day }}</h4>
                <user-offer
                    v-for="offer in data"
                    :key="offer.id"
                    :id="offer.id"
                    :age="offer.age"
                    :text="offer.message"
                    :hasReply="offer.hasReply"
                />
            </div>
        </v-container>
        <UserAgreementModal v-model="showDialog['user-agreement']"/>
        <PrivacyPolicyModal v-model="showDialog['privacy-police']"/>
        <SupportModal v-model="showDialog['support']"/>
    </div>
</template>

<script>
import TheHeader from '@/components/app/TheHeader'
import AppAlert from '@/components/app/AppAlert'
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal'
import SupportModal from '../modals/SupportModal'
import UserAgreementModal from '../modals/UserAgreementModal'
import UserOffer from '@/components/UserOffer'

export default ({
    name: 'ListController',
    data: () => ({
        data: {
        },
        email: '',
        emailConfirmed: false,
        dialogAnchor: window.location.hash.replace("#", ""),
        offers: [],
        showDialog: {
            'user-agreement': false,
            'privacy-police': false,
            'support': false,
        },
    }),
    methods: {
        search () {
            this.$axios.post('/api/frontend/offer/search', this.data)
                .then((res) => {
                    if (res.data.success) {
                        this.offers = res.data.data
                    }
                })
                .catch(() => {
                })
        },
    },
    created () {
        this.search()
        this.email = this.$store.getters.user.email || ''
        this.emailConfirmed = this.$store.getters.user.emailConfirmed || false
        if (this.dialogAnchor in this.showDialog) {
            this.showDialog[this.dialogAnchor] = true
            document.documentElement.classList.add('overflow-y-hidden')
        }
    },
    components: {
        TheHeader,
        AppAlert,
        PrivacyPolicyModal,
        SupportModal,
        UserAgreementModal,
        UserOffer,
    },
})
</script>

<style lang="scss" scoped>
.flex-container {
    padding-top: 16px;
    padding-bottom: 16px;
}
.offers-timestamp {
    margin-top: 12px;
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -0.3px;

    &:first-of-type {
        margin-top: 0;
    }
}
</style>
